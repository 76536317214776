import { createApp } from 'vue'
import App from './App.vue'
const app = createApp(App)
// app.config.devtools = true;

// 公共样式
import './assets/fonts/font.css';
import "./styles/common/common.scss";

// 路由
import index from './router'
app.use(index)

// vuex
import store from './store/index'
app.use(store)

// axios
import instance from './api/index.js';
import apiPath from './api/path.js';
import camundaPath from './api/camundaPath.js';
app.config.globalProperties.$http = instance;
app.config.globalProperties.$apiPath = apiPath;
app.config.globalProperties.$camundaPath = camundaPath;

// 全局过滤
import filters from "./utils/filters";
app.config.globalProperties.$filters = filters

// 按钮权限方法
import { hasBtnPermission } from './utils/hasBtnPermission.js';
app.config.globalProperties.hasBtnPer = hasBtnPermission


// mock
import axios from 'axios'
app.config.globalProperties.$axios = axios
import "./mockData/menuInfo.js";

import x2js from 'x2js' //xml数据处理插件
app.config.globalProperties.$x2js = new x2js() //全局方法挂载

// ElementPlus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
app.use(ElementPlus, {
    locale: zhCn,
})
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

import VueCodemirror from 'vue-codemirror'
app.use(VueCodemirror)

// form-create
import formCreate from '@form-create/element-ui'
import FcDesigner from '@form-create/designer'
import rmbCapital from "@/components/customFormCreater/RmbCapital.vue";
import calculateTotalSum from "@/components/customFormCreater/CalculateTotalSum.vue";
FcDesigner.component('rmbCapital',rmbCapital);
formCreate.component('rmbCapital',rmbCapital);
FcDesigner.component('calculateTotalSum',calculateTotalSum);
formCreate.component('calculateTotalSum',calculateTotalSum);
app.use(formCreate)
app.use(FcDesigner)

// 电子签名
import VueSignature from "vue-signature-pad";
app.use(VueSignature);



// vue-grid-layout
import VueGridLayout from 'vue-grid-layout';
app.use(VueGridLayout);

// syncfusion
import { registerLicense } from '@syncfusion/ej2-base';
// 20版
// registerLicense(`Mgo+DSMBaFt/QHJqVVhjWlpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF9jTnxTdkdhX3tWcHdSRA==;Mgo+DSMBPh8sVXJ0S0R+XE9HcFRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS3xTcUdjWX1cdnBdRmlcUw==;ORg4AjUWIQA/Gnt2VVhiQlFadVlJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkJhWX9ccnJRTmFbV0Y=;NjgwMzI1QDMyMzAyZTMyMmUzMGlpbVJndVh4WFU1RmJBZldyL1hHWFlDeklXeEhiQmFZaEVvYmVkcDJnbTQ9;NjgwMzI2QDMyMzAyZTMyMmUzMFRXclA0dUcydmpJVE1iZDNqREtscWNvYWRyT09kS3NCTXQ4Q2RnMzdwaVU9;NRAiBiAaIQQuGjN/V0Z+Xk9EaFxEVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdEVkW39ecnZTQmhZVEd3;NjgwMzI4QDMyMzAyZTMyMmUzMFFwdElhVFJTRmlHZDhzd1U5Y3NxbzU3SENHdkZINkN6SzA0K0h5aG5LOGs9;NjgwMzI5QDMyMzAyZTMyMmUzMEoyZzE2VlhZWEdRbEw4YzhqVWJERjJrdWdxNDh6bzNFQnpuaUpuUGVIZDQ9;Mgo+DSMBMAY9C3t2VVhiQlFadVlJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkJhWX9ccnJRTmhdVEY=;NjgwMzMxQDMyMzAyZTMyMmUzMGh3YWp2clN4UklmU2N4ZFVkSm41NW1icjBPcWloMHl3bHYyYTRzc3ZLMzg9;NjgwMzMyQDMyMzAyZTMyMmUzMGNxOUZrWkl6b1pLTHU1L2VqeXExNCtRbzNDSzdlakozUVp1NFlNZWUwUTg9;NjgwMzMzQDMyMzAyZTMyMmUzMFFwdElhVFJTRmlHZDhzd1U5Y3NxbzU3SENHdkZINkN6SzA0K0h5aG5LOGs9`);
// 21版
registerLicense('Mgo+DSMBaFt+QHFqVkNrXVNbdV5dVGpAd0N3RGlcdlR1fUUmHVdTRHRcQl5gSXxSckdnXnxWeX0=;Mgo+DSMBPh8sVXJ1S0d+X1RPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSX1QdkdiXX1ad3BVQmk=;ORg4AjUWIQA/Gnt2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5QdUVhWHtcdHNRR2hV;MTU2NTIwMkAzMjMxMmUzMTJlMzMzNVNUQ0xmMGo5VUZjZlpLeU9XcURVd0hFSytPdmdvSllac3hMcXV5NFhldmc9;MTU2NTIwM0AzMjMxMmUzMTJlMzMzNW83YWVrd0tLQnlJNENMN1B0cXlHUmg3c3NTSlRrb3hranpTMVR0TGRmdVE9;NRAiBiAaIQQuGjN/V0d+XU9Hc1RDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31TdUZjW35acnBSQmldWA==;MTU2NTIwNUAzMjMxMmUzMTJlMzMzNUdjSHJ6NXpjRktPanMwSDlkNGdEUGRPZ0pZcFI5VXBJMFRTV1NTSXRIWWM9;MTU2NTIwNkAzMjMxMmUzMTJlMzMzNXBFSS9nS0hPbnY4R2t2ZEdmbnRlOVpaamtkSVFNbnZjeUJFY3hlTzhad1k9;Mgo+DSMBMAY9C3t2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5QdUVhWHtcdHNQQGVV;MTU2NTIwOEAzMjMxMmUzMTJlMzMzNU42aWU1cEtUbGxWRkhnUGoxaVU4MHhXczZMeHlCMWJsODEvTVRmVURnRDg9;MTU2NTIwOUAzMjMxMmUzMTJlMzMzNUg0NDRPcWtRSnRmMFpIRmN5aWpqTmE4RlVWYVNuL0xhY2pHTUphdjN0bkE9;MTU2NTIxMEAzMjMxMmUzMTJlMzMzNUdjSHJ6NXpjRktPanMwSDlkNGdEUGRPZ0pZcFI5VXBJMFRTV1NTSXRIWWM9');


// @syncfusion/ej2-vue-navigations
import { SidebarPlugin, MenuPlugin, ToolbarPlugin, BreadcrumbPlugin, TreeViewPlugin, TabPlugin, AccordionPlugin } from '@syncfusion/ej2-vue-navigations';
app.use(SidebarPlugin)  // 侧栏
app.use(MenuPlugin)    // 菜单栏
app.use(ToolbarPlugin)  // 工具栏
app.use(BreadcrumbPlugin)  // 面包屑
app.use(TreeViewPlugin)  // 树形
app.use(TabPlugin) // 标签页
app.use(AccordionPlugin) // 手风琴
// @syncfusion/ej2-vue-buttons
import { ButtonPlugin, RadioButtonPlugin } from '@syncfusion/ej2-vue-buttons';
app.use(ButtonPlugin)  // 按钮
app.use(RadioButtonPlugin) // 单选

// @syncfusion/ej2-vue-grids
import { GridPlugin } from '@syncfusion/ej2-vue-grids';
app.use(GridPlugin)  // 表格
// @syncfusion/ej2-vue-treegrid
import { TreeGridPlugin } from '@syncfusion/ej2-vue-treegrid';
app.use(TreeGridPlugin);
// @syncfusion/ej2-vue-inputs
import { TextBoxPlugin, UploaderPlugin, NumericTextBoxPlugin } from '@syncfusion/ej2-vue-inputs';
app.use(TextBoxPlugin);  // 文本框
app.use(UploaderPlugin);  // 上传
app.use(NumericTextBoxPlugin);  // 数字文本框
// @syncfusion/ej2-vue-popups
import { DialogPlugin } from '@syncfusion/ej2-vue-popups';
app.use(DialogPlugin);  // 弹框
// @syncfusion/ej2-vue-layouts
import { SplitterPlugin } from '@syncfusion/ej2-vue-layouts';
app.use(SplitterPlugin);
// @syncfusion/ej2-vue-dropdowns
import { DropDownListPlugin } from '@syncfusion/ej2-vue-dropdowns';
app.use(DropDownListPlugin);   // 下拉列表
import { CalendarPlugin, DatePickerPlugin } from '@syncfusion/ej2-vue-calendars';
app.use(CalendarPlugin);  // 日历
app.use(DatePickerPlugin);  // 日期选择器
import { SchedulePlugin } from '@syncfusion/ej2-vue-schedule';
app.use(SchedulePlugin);   // 日程表


// bpmn 相关依赖
import 'bpmn-js/dist/assets/diagram-js.css'
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn.css'
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-codes.css'
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css'
// 左边工具栏以及编辑节点的样式
import 'bpmn-js-properties-panel/dist/assets/bpmn-js-properties-panel.css'


// 屏蔽错误信息
// app.config.errorHandler = () => null;
// 屏蔽警告信息
app.config.warnHandler = () => null;

app.mount('#app')
