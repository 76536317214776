const baseUrl = process.env.NODE_ENV == "development" ? window.gDev.ApiUrl : window.gEnv.ApiUrl;
const baseUrl3 = process.env.NODE_ENV == "development" ? window.gDev.ApiUrl3 : window.gEnv.ApiUrl3;
// 192.168.3.198:6001/api/v1/Hr/HrDepartment


let apiPath = {

    // 上传接口
    MongoStorage: baseUrl + 'api/MongoStorage',
    Details: baseUrl + 'details',

    // ---- 企业组织架构 -----
    HrCompany: baseUrl + 'api/HrCompany', // 公司信息
    HrDepartment: baseUrl + 'api/HrDepartment', // 部门信息
    HrDepartmentDetail: baseUrl + 'api/HrDepartmentDetail', // 部门详情
    HrPost: baseUrl + 'api/HrPosition', // 岗位信息
    HrEmployee: baseUrl + 'api/HrEmployee', // 员工信息
    HrRelation: baseUrl + 'api/HrRelation', // 公司-部门-员工-职位 关联关系

    // ---- 字典配置  -----
    // 通用

    // 业务相关
    DictSamplePosition: baseUrl + 'api/DictSamplePosition', // 采样位置
    DictChemicalInspectItem: baseUrl + 'api/DictChemicalInspectItem', // 化验检验项
    DicWorkflowType: baseUrl + 'api/DicWorkflowType', // 工作流分类
    Unit:baseUrl3+'api/Unit',  // 单位表

    // IOT相关
    UITopicMapDataKey: baseUrl + 'api/UITopicMapDataKey', //IoT大屏图形数据与消息匹配关系Api接口

    // 采样化验
    ChemicalInspectSampleData: baseUrl + 'api/ChemicalInspectSampleData', // 化验检验项目水质采样信息
    ChemicalInspectCheckedDataSet: baseUrl + 'api/ChemicalInspectCheckedDataSet', // 化验检验项目水质化验结果


    // ----  工作流程相关 ----
    WorkflowAssigneeInfo: baseUrl + 'api/WorkflowAssigneeInfo', //记录用户审批任务执行人
    UserTask: baseUrl + 'api/UserTask', //用户任务设置、获取
    WorkflowMapPositions: baseUrl + 'api/WorkflowMapPositions', //工作流一个审批环节，多部门审核配置 用途：需要发起人所在
    WorkflowOnlookConfig: baseUrl + 'api/WorkflowOnlookConfig', //记录工作流抄送用户接口
    WorkflowOnlookTask: baseUrl + 'api/WorkflowOnlookTask', //用户需要处理的抄送任务

    // 自定义表结构
    DicTableName: baseUrl + 'api/DicTableName', // 数据表名称配置
    DicColumnType: baseUrl + 'api/DicColumnType', // 列配置属性


    // 运维站点---岳
    stationLevel: baseUrl3 + 'api/stationLevel', // 站点分类
    station: baseUrl3 + 'api/station', // 站点基本信息
    mqttTopic: baseUrl3 + 'api/mqttTopic', // mqtt主题
    mqttTopicMap: baseUrl3 + 'api/mqtttopickeytolabel', // 根据主键ID获取全部主题与消息匹配表信息
    appendix: baseUrl3 + 'api/appendix', // 附件
    stationConfig: baseUrl3 + 'api/stationConfig', // Iot项目 站点配置
    mqttTopicConfig: baseUrl3 + 'api/MqttTopicConfig', // 站点主题其他配置

    // 碳排放
    carbonDayReduction:baseUrl3+'api/CarbonDayReduction',  // 碳排放日数据
    carbonMonthReduction:baseUrl3+'api/CarbonMonthReduction',  // 碳排放月数据

    // 设备
    deviceClassify:baseUrl3+'api/DeviceClassify', // 设备分类


    // 工作流任务相关---岳
    processDefinition: baseUrl3 + 'api/Process', // 工作流程
    processClassify: baseUrl3 + 'api/ProcessClassify', // 流程分类
    processConfig: baseUrl3 + 'api/ProcessConfig', // 流程分类与流程信息配置
    scheduleJob: baseUrl3 + 'api/scheduleJob', // 定时任务
    userTasks: baseUrl3 + 'api/UserTask', // 用户任务
    formTemplate: baseUrl3 + 'api/FormTemplate', // 流程表单
    formField: baseUrl3 + 'api/FormField', //流程表单关联字段
    taskDelegate: baseUrl3 + 'api/TaskDelegate', // 任务分配
    workflowOnlookConfig1: baseUrl3 + 'api/WorkflowOnlookConfig', // 任务抄送配置
    workflowOnlookTask1: baseUrl3 + 'api/WorkflowOnlookTask', // 用户抄送任务
    reportFormConfig: baseUrl3 + 'api/ReportFormConfig', //报表配置


    // 消息通知
    information: baseUrl3 + 'api/Information', // 消息通知

    // 系统管理 ---岳
    systemMenu: baseUrl3 + 'api/Menu', // 菜单管理
    systemRole: baseUrl3 + 'api/Role', // 角色管理

    // 企业架构  --- 岳
    company: baseUrl3 + 'api/Company', // 公司信息
    companyDetail: baseUrl3 + 'api/CompanyDetail', // 公司详情
    department: baseUrl3 + 'api/Department', // 部门架构
    departmentDetail: baseUrl3 + 'api/DepartmentDetail', // 部门详情
    position: baseUrl3 + 'api/Position', // 职位架构
    employee: baseUrl3 + 'api/Employee', // 员工信息
    hrRelation: baseUrl3 + 'api/HrRelation', // 组织关系
    group: baseUrl3 + 'api/Group', // 分组
    groupRelation: baseUrl3 + 'api/GroupRelation', // 分组关系
    businessRole: baseUrl3 + 'api/BusinessRole', //业务角色
    employeeSign:baseUrl3+'api/employeeSign',   // 员工签名


    // 脚本接口
    ScriptFunction: baseUrl3 + 'api/ScriptFunction', // 根据name获取
    tableHeader: baseUrl3 + 'api/tableHeader', // 根据name获取
    apk: baseUrl3 + 'api/apk', // 根据name获取
    apkList: baseUrl3 + 'api/apk/list', // 获取所有版本列表
    apkVersion: baseUrl3 + 'api/apkVersion', // apk版本



















    //   -------------------------- 弃用------------------------------
    // 关联表
    // AccountInfo: baseUrl +'/AccountInfo',   // 银行账户信息
    // CertificateInfo: baseUrl + '/CertificateInfo',   // 证书资料附件
    ContactInfo: baseUrl + 'api/v1/Common/ContactInfo/', //  联系方式:  拼接：HrCompany 公司，HrDepartment 部门，HrEmployee 员工


    // ----- 运维管理 -------
    // 运维配置
    AlarmSetting: baseUrl + 'api/v1/ScreenShow/AlarmSetting', // 报警配置
    LineInspectSetting: baseUrl + 'api/v1/ScreenShow/LineInspectSetting', // 巡检设置
    RepairSetting: baseUrl + 'api/v1/ScreenShow/RepairSetting', // 维修设置
    WorkSchedual: baseUrl + 'api/v1/ScreenShow/WorkSchedual', // 班次安排
    WorkerTimeSchedual: baseUrl + 'api/v1/ScreenShow/WorkerTimeSchedual', // 值班安排

    // 记录等
    LineInspectRecord: baseUrl + 'api/v1/ScreenShow/LineInspectRecord', // 巡检记录
    WarnRecord: baseUrl + 'api/v1/ScreenShow/WarnRecord', // 预警记录
    AlarmRecord: baseUrl + 'api/v1/ScreenShow/AlarmRecord', // 报警记录
    RepairRecord: baseUrl + 'api/v1/ScreenShow/RepairRecord', // 维修记录
    DeviceStatus: baseUrl + 'api/v1/ScreenShow/DeviceStatus', // 设备状态
    CommunicationStatus: baseUrl + 'api/v1/ScreenShow/CommunicationStatus', // 系统通信状态
    ReagentAmount: baseUrl + 'api/v1/ScreenShow/ReagentAmount', // 用药记录
    SpareParts: baseUrl + 'api/v1/ScreenShow/SpareParts', // 备品备件
    ReagentStock: baseUrl + 'api/v1/ScreenShow/ReagentStock', // 药品库存
    VehicleRecord: baseUrl + 'api/v1/ScreenShow/VehicleRecord', // 车辆使用记录
    CheckInOut: baseUrl + 'api/v1/ScreenShow/CheckInOut', // 班组签到
    StationLeader: baseUrl + 'api/v1/Hr/StationLeader', // 站点负责人

    // 值班人员  关联班次安排的id
    // 处置内容


    // ---- 字典配置  -----
    // 通用
    Gender: baseUrl + 'api/v1/BaseDict/Gender', // 性别
    Nation: baseUrl + 'api/v1/BaseDict/Nation', // 民族信息
    ContactType: baseUrl + 'api/v1/BaseDict/ContactType', // 联系方式分类
    CertificateType: baseUrl + 'api/v1/BaseDict/CertificateType', // 证书分类

    // 业务相关
    PostCategoryType: baseUrl + 'api/v1/BaseDict/PostCategoryType', // 岗位分类
    UnitType: baseUrl + 'api/v1/BaseDict/UnitType', // 计量单位
    TableAlias: baseUrl + 'api/v1/BaseDict/TableAlias', // 关联表别名

};

export default apiPath;
